export const scrollTo = {
  go: function(in_class, margin = 40){
    const position = in_class.offset().top - margin;
    $("html,body").animate({
      scrollTop : position
    }, {
      queue : false
    });
  },
  // overfow scroll
  goInOverflowScroll: function(in_class, container, margin = 40) {
    if (container.css('overflow') === 'scroll') {
      container.animate({
        scrollTop : in_class.offset().top - margin
      }, {
        queue : false
      });
    } else {
      this.scroll(in_class, margin);
    }
  }
};