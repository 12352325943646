// for rails only
export class Validator {
  constructor() {
    this.inputTagSelector = 'input:visible,textarea:visible';
  }
  //
  // life cycle
  //
  initialize(formTarget) {
    this.toggleSubmit(formTarget);
  }
  rebind() {
    $('form[data-component="validate"]').each((index, element) => {
      this.initialize(element);
    });
  }
  addEvent() {
    // 初期化
    $(document).ready(() => {
      $('form[data-component="validate"]').each((index, element) => {
        window.components.validator.initialize(element);
      });
    });

    $('body').on('keyup change', 'form[data-component="validate"] input, form[data-component="validate"] textarea', (e) => {
      window.components.validator.keyup(e.target);
    });
  }
  //
  // data
  //
  data(formTarget) {
    const $formTarget = $(formTarget);
    const option = {
      submitDisable: false,
      auto: {
        require: false
      }
    };
    if ($formTarget.data('auto')) {
      if ($formTarget.data('auto').match(/require/)) {
        option.auto.require = true;
      }
    }
    if ($formTarget.data('submit-disable')) {
      option.submitDisable = true;
    }
    return { option: option };
  }
  //
  // target
  //
  formTarget(element) {
    const $element = $(element);
    return $element.closest('form[data-component="validate"]')[0];
  }
  inputTargets(formTarget) {
    return $(formTarget).find(this.inputTagSelector);
  }
  submitTargets(formTarget){
    const targets = [];
    // submit
    const $formTarget = $(formTarget);
    $formTarget.find('input[type="submit"], button[type="submit"]').each((index, element) => {
      targets.push(element);
    });
    // aにvalidateが付いているもの
    $formTarget.find('a[data-target*="validate"]').each((index, element) => {
      targets.push(element);
    });
    return targets;
  }
  //
  // public methods
  //

  // formの入力中の処理
  keyup(element) {
    var formTarget = this.formTarget(element);
    // エラー状態の解除
    this.resolveErrors(formTarget);
    // submitのチェック
    this.toggleSubmit(formTarget);
  }
  // AJAXでエラーになっている場合のエラー付与
  errorsAdd(messages) {
    if (!messages) {
      return;
    }
    messages.forEach((struct, index, array) => {
      var id = struct.id.replace(/-/g, '_');
      this.addErrorToColumn(id, struct.message);
    });
  }

  //
  // private methods
  //

  //
  // validate
  //
  // フォームを送信できるかチェックする。$(xxx).validateで呼び出される実質private
  isValid($submit) {
    if ($submit.prop("tagName") != 'A') {
      return true;
    }
    // formがvalidation componentを持っているか確認する
    const formTarget = $submit.closest('form');
    if (!formTarget.data('component') || formTarget.data('component') != 'validate') {
      console.log('this is not a validated form');
      return true;
    }
    const errors = this.validateAllColumns(formTarget);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    // errorクラスを付与する
    // config/initializers/field_with_errors.rbと同期させる
    Object.keys(errors).forEach((id, index, array) => {
      const message = errors[id];
      this.addErrorToColumn(id, message);
    });
    const result = true;
    return result;
  }

  validateAllColumns(formTarget) {
    const inputs = this.inputTargets(formTarget);
    const option = this.data(formTarget).option;
    const errors = {};
    inputs.each((index, element) => {
      // { sample_param_string: "入力が必要です" } のように、エラーがあれば、{ id: message }が返ってくる
      const error = this.validateColumn(element, option);
      if (error) {
        Object.assign(errors, error);
      }
    });
    return errors;
  }

  validateColumn(element, option) {
    const errorMessagesArray = [];
    const formTarget = this.formTarget(element);
    // required チェック
    const errors = this.validateRequired(element, option);
    if (errors) {
      errorMessagesArray.push(errors);
    }
    return errorMessagesArray[0];
  }

  // require
  validateRequired(element, option) {
    const $element = $(element);
    // requireカラムでなければ抜ける
    if (option.auto.require) {
      //  下記の構造の必要がある。この場合、rootから見て、childrenにis-optinalがなければ必須パラメータとなる
      // .form-group.row
      //   .col-md-xx.col-form-label.is-optional
      const $element = $(element);
      const $root = $element.closest('.form-group');
      if ($root.children('.is-optional')[0]) {
        return null;
      }
    } else {
      const validateAttribute = $element.attr('validate');
      if (!validateAttribute || !validateAttribute.match(/:require=>true/)) {
        return null;
      }
    }
    // 値があるかチェックする
    const id = $element.attr("id");
    let error = false;
    // text_field
    if ($element.prop("tagName") === 'INPUT' && $element.attr("type") === 'text') {
      if (!$element.val()) {
        error = true;
      }
    }
    // text_area
    if ($element.prop("tagName") === 'TEXTAREA') {
      if (!$element.val()) {
        error = true;
      }
    }
    if (error) {
      let resultHash = {};
      resultHash[id] = '入力が必要です';
      return resultHash;
    } else {
      return null;
    }
  }

  //
  // Dom操作
  //
  // Submitボタンの表示/非表示
  toggleSubmit(formTarget, errors = null) {
    if (!this.data(formTarget).option.submitDisable) {
      return;
    }
    // validateAllColumnsはコストが高いので、別の場所で実行されたものを引き継げるようにする
    if (!errors) {
      errors = this.validateAllColumns(formTarget);
    }
    // エラーがあれば、submitをdisableにする
    if (Object.keys(errors).length != 0) {
      this.submitTargets(formTarget).forEach((submit, index, array) => {
        const $submit = $(submit);
        if ($submit.prop("tagName") === 'INPUT') {
          $submit.prop('disabled', true);
        // aの場合
        } else {
          // data-actionは退避が必要と思われる
          $submit.addClass('disabled');
        }
      }) ;
    } else {
      this.submitTargets(formTarget).forEach((submit, index, array) => {
        const $submit = $(submit);
        if ($submit.prop("tagName") === 'INPUT') {
          $(submit).prop('disabled', false);
        } else {
          $submit.removeClass('disabled');
        }
      }) ;
    }
  }

  // errorが解消しているところを消す
  resolveErrors(formTarget, errors = null) {
    var self = this;
    // validateAllColumnsはコストが高いので、別の場所で実行されたものを引き継げるようにする
    if (!errors) {
      errors = this.validateAllColumns(formTarget);
    }

    var $formTarget = $(formTarget);
    // エラーではなくなっているのに、field_with_errorがついているものを探す
    var currentErrorIds = Object.keys(errors);
    var fieldWithErrorIds = [];
    $formTarget.find('.field_with_errors').each(function(index, element){
      $(element).children(this.inputTagSelector).each(function(index, element){
        if (element.id) {
          fieldWithErrorIds.push(element.id);
        }
      });
    });
    var shouldRemoveIds = [];
    fieldWithErrorIds.forEach(function(id, index, array){
      if (!currentErrorIds.includes(id)) {
        shouldRemoveIds.push(id);
      }
    });
    shouldRemoveIds.forEach(function(id, index, array){
      self.removeErrorFromColumn(id);
    });
    // フォーカス中だったため仮classに退避させたものをunwrapする
    this.clearStashedErrors(formTarget);
  }

  // エラーをカラムに付与する
  addErrorToColumn(id, message) {
    var $html = $('#' + id);
    var exceptions = ['radio', 'checkbox'];
    var exception_falg = false;
    exceptions.forEach(function(type, index, array){
      if ($html.attr('type') == type) {
        if (!exception_falg) {
          exception_falg = true;
        }
      }
    });

    if ($html.parent().hasClass('field_with_errors')) {
      return;
    }

    if (exception_falg) {
    } else {
      // 「focus中だったのでstashさせたエラー表示」の再利用をするかどうか
      if ($html.parent().hasClass('field_with_errors_stashed')) {
        $html.parent().removeClass('field_with_errors_stashed');
        $html.parent().addClass('field_with_errors');
      } else {
        $html.wrap('<div class="field_with_errors"></div>');
      }
      $html.after('<div class="invalid-feedback">' + message + '</div>');
    }
  }

  // エラーをカラムから消す
  removeErrorFromColumn(id) {
    var $html = $('#' + id);
    var $parent = $html.parent();
    $parent.children('.invalid-feedback').remove();

    // 入力中の要素に対して要素の移動を起こすとblur状態になってしまうのでNG。一旦 field_with_errors_tmpに切り替えて、選択中でなくなったときに消す。
    if ($html.is(':focus')) {
      $parent.removeClass('field_with_errors');
      $parent.addClass('field_with_errors_stashed');
    } else {
      $html.unwrap();
    }
  }
  // 入力中にエラーをカラムから消す
  clearStashedErrors(formTarget) {
    $(formTarget).find('.field_with_errors_stashed').each(function(index, parent){
      $(parent).children(this.inputTagSelector).each(function(index, input){
        // 入力中は移動してはいけない
        if (!$(input).is(':focus')) {
          $(input).unwrap();
        }
      });
    });
  }
};

// submitにvalidateを実行すると、validatorを実行するようにする
(function($) {
  $.fn.validate = function() {
    // thisは$オブジェクト
    return window.components.validator.isValid(this);
  };
})($);
