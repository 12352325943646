export const btn = {
  addEvent(e) {
    // 「保存しました」をデフォルトに戻す
    $(document).on('change', 'input', () => {
      $('a.btn-target').each((index, element) => {
        var e = { target: element };
        this.reset(e);
      });
    });
  },
  btnTarget(e) {
    const $target = $(e.target);
    if ($target.prop("tagName") == 'A' || $target.prop("tagName") == 'BUTTON') {
      return $target;
    }
    let $btn = $target.find('button[type="submit"]');
    if ($btn) {
      return $btn;
    }
    return $target.find('button');
  },
  sending(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == 'A') {
      $btn.addClass("disabled");
    }
    $btn.addClass('btn-target');
    const originalText = $btn.text();
    // $btn.html("<img class='btn-submit-loading' src='https://writers-public-prod.s3-ap-northeast-1.amazonaws.com/static/images/global/loading/loading_submit.svg'>送信中");
    $btn.attr('original-text', originalText);
    $btn.prop("disabled", true);
  },
  reset(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == 'A') {
      $btn.removeClass("disabled");
    } else {
      $btn.prop("disabled", false);
    }
    $btn.removeClass('btn-success');
    $btn.removeClass('btn-warning');
    const originalText = $btn.attr('original-text');
    $btn.html(originalText);
  },
  error(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == 'A') {
      $btn.removeClass("disabled");
    }
    $btn.addClass('btn-warning');
    $btn.html('失敗しました');
  },
  success(e) {
    const $btn = this.btnTarget(e);
    if ($btn.prop("tagName") == 'A') {
      $btn.removeClass("disabled");
    }
    $btn.addClass('btn-success');
    $btn.html('保存しました');
  },
};


