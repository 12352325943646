//
// Popup形式のアラート。alert()の代替のため、「閉じる」のみ。
//

export const alertPopup = {
  // life cycle
  addEvent(e) {
    // クリックから実行する場合
    $('[data-toggle="alert-popup"]').on('click', (e) => {
      this.callByClick(e);
    });
  },
  // data
  get data() {
    return {
      alertID: '#alertPopup'
    };
  },
  callByClick(e) {
    console.log(e);
    var $this = $(e.target);
    var title = $this.data('title');
    var messages = $this.data('messages');
    this.show(title, messages);
  },
  callByFunction(title, messages) {
    this.show(title, messages);
  },
  getAlert(title, messages) {
    var $alert = $(this.data.alertID);
    // 破棄する
    if ($alert[0]) {
      $alert.remove();
    }
    var html = "";
    html += "<div id='alertPopup' class='modal fade'>";
    html += "  <div class='modal-dialog'>";
    html += "    <div class='modal-content'>";
    if (title) {
      html += "      <div class='modal-header'>";
      html += "        <div class='modal-title'>";
      html += title;
      html += "        </div>";
      html += "        <a class='close' data-dismiss='modal'>";
      html += "        </a>";
      html += "      </div>";
    }
    html += "      <div class='modal-body'>";
    html += "        <ul>";
    messages.forEach(function(message, index, array){
      html += "          <li>";
      html += message;
      html += "          </li>";
    });
    html += "        </ul>";
    html += "      </div>";
    html += "      <div class='modal-footer'>";
    html += "        <div class='modal-footer-single'>";
    html += "          <a class='btn btn-text' href='' data-dismiss='modal'>";
    html += "            閉じる";
    html += "          </a>";
    html += "        </div>";
    html += "      </div>";
    html += "    </div>";
    html += "  </div>";
    html += "</div>";
    $('body').append(html);
    $alert = $(this.data.alertID);
    return $alert;
  },
  show(title, messages) {
    var $alert = this.getAlert(title, messages);
    $alert.modal('show');
  }
};

// 他のJSから起動する場合
// window.components.alertPopup.callByFunction('title', 'messages');



