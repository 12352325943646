export const switcherIfValue = {
  get data() {
    return {
      classRadioTrigger: '.jsIfValueRadio',
      classTarget: '.jsIfValueIs'
    };
  },
  addEvent() {
    $('.jsIfValue').each((i, input) => {
      this.setup($(input));
    });
    $('body').on('change', ".jsIfValue", (e) =>{
      this.setup($(e.target));
    });
    // type radio
    $(".jsIfValueRadio").each((i, input) => {
      var $input = $(input);
      $input.find('input[type="radio"]').each((index, input) => {
        if (index > 0) { return false; }
        this.setupRadio($(input));
      });
    });
    $('body').on('change', ".jsIfValueRadio input[type='radio']", (e) => {
      this.setupRadio($(e.target));
    });
  },
  setup($input) {
    const name = $input.attr('name');
    let value = $input.val();
    const type = $input.attr('type');
    if (type && type == 'radio') { console.log('warning: please use jsIfValueRadio'); }
    if (type && type == 'checkbox') {
      value = $input.prop('checked');
    }
    this.toggle(name, value);
  },
  setupRadio($input) {
    const $radioParent = $input.closest(this.data.classRadioTrigger);
    const $radios = $radioParent.find('input');
    let name;
    let value;
    $radios.each((index, element) => {
      const $element = $(element);
      if (index == 0) {
        name = $element.attr('name');
      }
      if ($element.prop('checked')) {
        value = $element.val();
      }
    });
    this.toggle(name, value);
  },
  toggle(name, value) {
    $(this.data.classTarget + "[data-name='" + name + "']").each((index, element) => {

      const $element = $(element);
      const elementValue = $element.data('value');
      if (Object.prototype.toString.call(elementValue) == "[object String]") {
        // 配列
        if ( elementValue.match(/,/)) {
          var valueArray = elementValue.split(',');
          if (valueArray.indexOf(value) >= 0){
            $element.addClass('active');
          } else {
            $element.removeClass('active');
          }
        // 文字列
        } else {
          if (elementValue == value) {
            $element.addClass('active');
          } else {
            $element.removeClass('active');
          }
        }
      // 数値
      } else {
        if (elementValue == value) {
          $element.addClass('active');
        } else {
          $element.removeClass('active');
        }
      }
    });
  }
};

