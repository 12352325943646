
//
// plugins
//
require('bootstrap/js/dist/alert.js');
require('bootstrap/js/dist/dropdown.js');
require('bootstrap/js/dist/modal.js');
require('bootstrap/js/dist/tooltip.js');
require('bootstrap/js/dist/popover.js');


//
// Components
//
if (!window.components) { window.components = {}; }

const { alertPopup } = require('../components/alert-popup.js');
alertPopup.addEvent();
window.components.alertPopup = alertPopup;

const { AlertSlide } = require('../components/alert-slide.js');
const alertSlide = new AlertSlide();
alertSlide.addEvent();
window.components.alertSlide = alertSlide;

const { btn } = require('../components/btn');
btn.addEvent();
window.components.btn = btn;

const { confirmation } = require('../components/confirmation.js');
confirmation.addEvent();

const { formsCheckbox } = require('../components/forms-checkbox.js');
formsCheckbox.addEvent();

const { FormsChip } = require('../components/forms-chip.js');
const formsChip = new FormsChip();
formsChip.addEvent();
window.components.formsChip = formsChip;

const { formsRadio } = require('../components/forms-radio.js');
formsRadio.addEvent();

const { formsTextCount } = require('../components/forms-text-count.js');
formsTextCount.addEvent();

const { formsTextAreaExample } = require('../components/forms-textarea-example.js');
formsTextAreaExample.addEvent();

require('../components/forms-suggest.js');

const { imageUploader} = require('../components/image-uploader.js');
imageUploader.addEvent();

const { MenuSpSub } = require('../components/menu-sp-sub.js');
const menuSpSub = new MenuSpSub();
menuSpSub.addEvent();
window.components.menuSpSub = menuSpSub;

const { menuSp } = require('../components/menu-sp.js');
menuSp.addEvent();


require('../components/menu-fixed.js');

// rails only
require('../components/modal-notice.js');

const { ProgressBar } = require('../components/progress-bar.js');
const progressBar = new ProgressBar();
progressBar.addEvent();
window.components.progressBar = progressBar;

const { switcherIfValue } = require('../components/switcher-if-value.js');
switcherIfValue.addEvent();

const { switcher } = require('../components/switcher.js');
switcher.addEvent();

const { toggleSwitch } = require('../components/toggle-switch.js');
toggleSwitch.addEvent();

const { Validator} = require('../components/validator.js');
const validator = new Validator();
validator.addEvent();
window.components.validator = validator;


//
// Helpers
//
if (!window.helpers) { window.helpers = {}; }
const { getFormsIncludesFile } = require('../helpers/get-forms-includes-file.js');
window.helpers.getFormsIncludesFile = getFormsIncludesFile;

const { scrollTo } = require('../helpers/scroll-to.js');
window.helpers.scrollTo = scrollTo;


//
// Initializers
//
require('../initializers/bootstrap.js');
require('../initializers/knockout.js');
require('../initializers/ujs.js');


//
// Partials
//
// 以下のようにViewから読み込む
// @javascript_features += ['kaminari/_paginator']
