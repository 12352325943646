// safariでsubmitと同時にjsが固まるのを防ぐ
document.addEventListener(
  'click',
  event => {
    let element = event.target;
    if (element.dataset.disableWithSafari) return;
    if (element.dataset.disableWith) {
      element.disabled = true;
      element.innerHTML = element.dataset.disableWith;
      let $form = $(element).closest('form');
      if ($form.length) {
        event.preventDefault();
        setTimeout(() => $form.submit(), 150);
        element.dataset.disableWithSafari = true;
        element.click();
      }
    }
  },
  true
);