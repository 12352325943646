export const menuSp = {
  addEvent() {
    $('body').on('click', '[data-action="spMenuOpen"]', () => {
      this.open();
    });
    $('body').on('click', '[data-action="spMenuClose"]', () => {
      this.close();
    });
  },
  rootTarget() {
    return $('[data-target="spMenuRoot"]');
  },
  open(){
    const $rootTarget = this.rootTarget();
    $rootTarget.addClass('active');
    $rootTarget.addClass('style-transition');
    setTimeout(() => $rootTarget.addClass('transition-background'), 1);
    setTimeout(() => $rootTarget.addClass('transition-list'), 170);
  },
  close(){
    const $rootTarget = this.rootTarget();
    $rootTarget.removeClass('transition-background');
    $rootTarget.removeClass('transition-list');
    setTimeout(() => $rootTarget.removeClass('active'), 300);
  },
};
