// Safari11で、Ajax送信時に、空のinput[type=file]があるとハングするため、回避を行う
export const getFormsIncludesFile = {
  getForm(form) {
    const tempForm = $(form);
    const newForm = tempForm.clone(true);
    newForm.find("input[type=file]").each(function() {
      if($(this).val() === "") {
        $(this).remove();
      }
    });
    // cloneに不具合があるため、selectの内容をコピーし直す
    var selectValArray = [];
    tempForm.find("select").each(function() {
      selectValArray.push($(this).val());
    });

    newForm.find("select").each(function(index, element) {
      $(element).val(selectValArray[index]);
    });
    return newForm;
  }
};
