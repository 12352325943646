// modal内にアラートを出す。
// vueの場合は、vueテンプレートで対応できるので不要
(function($) {
  $.fn.modalNotice = function(message) {
    var $modal = $(this);
    var $bodyInfo  = $modal.find('.modal-info');
    $bodyInfo.remove();

    var $body  = $modal.find('.modal-body:visible');
    $body.before('<div class="modal-info alert alert-info alert-dismissible fade show"><div class="close" data-dismiss="alert"></div><div class="container">' + message + '</div></div>');
    return this;
  };

  $.fn.modalAlert = function(message) {
    var $modal = $(this);
    var $bodyInfo  = $modal.find('.modal-info');
    $bodyInfo.remove();
    var $body  = $modal.find('.modal-body:visible');
    $body.before('<div class="modal-info alert alert-warning alert-dismissible fade show"><div class="close" data-dismiss="alert"></div><div class="container">' + message + '</div></div>');
    return this;
  };

  $.fn.modalInfoClose = function(message) {
    var $modal = $(this);
    var $bodyInfo  = $modal.find('.modal-info');
    $bodyInfo.remove();
    return this;
  };
})($);