export class FormsChip {
  constructor() {
    this.componentRoot = '.form-chip';
    this.chipsRoot  = '.form-chip-root';
    this.chipLabel  = '.form-chip-label';
    this.chipDelete = '.form-chip-delete';
    this.inputText  = '.form-chip-input';
  }
  setup($root) {
    //setup textfield
    this.getTextField($root);
    this.drawChip($root);
  }
  addEvent() {
    // 初期化
    $(document).ready(() => {
      $('.form-chip[data-toggle="chip"]').each((i, chip) => {
        this.setup($(chip));
      });
    });

    // http://semooh.jp/jquery/api/events/keypress/fn/
    $(document).on('keyup', ".form-chip input[type='text']", (e) => {
      this.keyupController(e, $(e.target));
    });

    $(document).on('keydown', ".form-chip input[type='text']", (e) => {
      this.keydownController(e, $(e.target));
      // エンターのフォーム送信を無効にする
      if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
        return false;
      }
    });

    // out focus
    $(document).on('blur', ".form-chip input[type='text']", (e) => {
      this.addChip($(e.target));
    });

    $(document).on('click', ".form-chip .form-chip-delete", (e) => {
      this.delChip($(e.target));
    });

    // input外をクリックしても追加になれるようにアタリを広げる
    $(document).on('click', ".form-chip .form-chip-root > ul", (e) => {
      e.stopPropagation();
    });

    $(document).on('click', ".form-chip .form-chip-root", (e) => {
      this.textFieldFocus(e, $(e.target));
    });
  }
  //
  // Target
  //
  getRoot(el) {
    const $root = $(el).closest(this.componentRoot);
    return $root;
  }
  getChipsRoot($root) {
    let $chipsRoot = $root.children(this.chipsRoot);
    if (!$chipsRoot[0]) { 
      $root.prepend("<div class='" + this.chipsRoot.replace('.','') + "'></div>");
      $chipsRoot = $root.children(this.chipsRoot);
    }
    return $chipsRoot;
  }
  getListRoot($root) {
    const $chipsRoot = this.getChipsRoot($root);
    let $listRoot = $chipsRoot.children('ul');
    if (!$listRoot[0]) { 
      $chipsRoot.prepend("<ul></ul>");
      $listRoot = $chipsRoot.children('ul');
    }
    return $listRoot;
  }
  getLists($root) {
    const $listRoot = this.getListRoot($root);
    const $lists = $listRoot.children('li:not('+ this.inputText +')');
    return $lists;
  }
  getLastList($root) {
    const $lists = this.getLists($root);
    return $lists.last();
  }
  getTextField($root) {
    const $listRoot = this.getListRoot($root);
    let $textField = $listRoot.find('input');
    if (!$textField[0]) { 
      $listRoot.append("<li class='"+ this.inputText.replace('.','') +"'><input type='text' placeholder='" + $root.data('placeholder')+ "'></li>");
      $textField = $listRoot.find('input');
    }
    return $textField;
  }
  getListTextFiled($textfield) {
    const $listTextFiled = $textfield.closest(this.inputText);
    return $listTextFiled;
  }
  getHiddenField($root) {
    const $hidden = $root.children('input[type="hidden"]');
    return $hidden;
  }
  getLabelsArray($root) {
    const $lists = this.getLists($root);
    const array = [];
    $lists.each((index, element) => {
      const $list = $(element);
      const label = $list.children(this.chipLabel).text();
      array.push(label);
    });
    return array;
  }

  //
  // methods
  //
  newChip(value) {
    return "<li><span class='" + this.chipLabel.replace('.','') + "'>" + value + "</span><span class='"+ this.chipDelete.replace('.','') + "'></li>";
  }
  addChip($textField) {
    const $root = this.getRoot($textField);
    let value = $textField.val();
    // 取得したら消しておく
    $textField.val('');
    value = value.replace(/ /g,'');
    value = value.replace(/　/g,'');

    const $hiddenField = this.getHiddenField($root);
    const hiddenValue = $hiddenField.val();
    let hiddenArray = [];
    if (hiddenValue != "") {
      hiddenArray = $hiddenField.val().split(',');
    }
    if (value) {
      if (!hiddenArray.includes(value)) {
        hiddenArray.push(value);
      }
    }
    $hiddenField.val(hiddenArray.join(','));

    // 再描画
    this.drawChip($root);
  }
  delChip($chipSpan) {
    const $chip = $chipSpan.closest('li');
    const label = $chip.children(this.chipLabel).text();
    const $root = this.getRoot($chip);
    const $hiddenField = this.getHiddenField($root);
    const labelArray = $hiddenField.val().split(',');
    const newArray = [];
    labelArray.forEach((value, index, array) => {
      if (value != label) {
        newArray.push(value);
      }
    });
    $hiddenField.val(newArray.join(','));

    // 再描画
    this.drawChip($root);
  }
  drawChip($root) {
    const $listRoot = this.getListRoot($root);
    const $textField = this.getTextField($root);
    const $inputList = $textField.closest('li');
    const hiddenField = this.getHiddenField($root);
    const hiddenArray = hiddenField.val().split(',');
    const rootThis = this;
    const labelsArray = this.getLabelsArray($root);
    // 追加する場合
    hiddenArray.forEach((hiddenValue, index, array) => {
      if (hiddenValue != "") {
        const isExist = $.inArray(hiddenValue, labelsArray);
        if (isExist == -1) {
          $inputList.before(rootThis.newChip(hiddenValue));
        }
      }
    });

    const $lists = this.getLists($root);
    // 削除する場合
    $lists.each((index, element) => {
      const $list = $(element);
      const labelValue = $list.children(this.chipLabel).text();
      const isExist = $.inArray(labelValue, hiddenArray);
      if (isExist == -1) {
        $list.remove();
      }
    });
  }
  setTextCount($textField) {
    // upでないと、正確なcountが取れない
    const value = $textField.val();
    const count = value.length;
    const $listParent = this.getListTextFiled($textField);
    $listParent.data('count', count);

    // delFlag
    const delFlag = this.getDelFlag($textField);
    if (delFlag == true && count > 0) {
      this.disableDelFlag($textField);
    }
  }
  getTextCount($textField) {
    const $listParent = this.getListTextFiled($textField);
    let count = $listParent.data('count');
    if (!count) { count = 0;}
    return count;
  }
  getDelFlag($textField) {
    const $listParent = this.getListTextFiled($textField);
    let delFlag = $listParent.data('del-flag');
    if (!delFlag) { delFlag = false;}
    return delFlag;
  }
  enableDelFlag($textField) {
    const $listParent = this.getListTextFiled($textField);
    const delFlag = $listParent.data('del-flag');
    if (!delFlag || delFlag == false) {
      $listParent.data('del-flag', true);
      // chipのスタイルをアクティブに
      const $root = this.getRoot($textField);
      const $lastList = this.getLastList($root);
      $lastList.addClass('is-del-flag');
    }
  }
  disableDelFlag($textField) {
    const $listParent = this.getListTextFiled($textField);
    const delFlag = $listParent.data('delFlag');
    if (!delFlag || delFlag == true) {
      $listParent.data('del-flag', false);
      // chipのスタイルをインアクティブに
      const $root = this.getRoot($textField);
      const $lastList = this.getLastList($root);
      $lastList.removeClass('is-del-flag');
    }
  }
  keyDelChip($textField) {
    const count = this.getTextCount($textField);
    if (count == 0) {
      const delFlag = this.getDelFlag($textField);
      // 削除準備
      if (delFlag == false) {
        this.enableDelFlag($textField);

      // 削除実行
      } else {
        // 最後のchipを削除する
        const $root = this.getRoot($textField);
        const $lastList = this.getLastList($root);
        var $span = $lastList.children(this.chipDelete);
        if ($span[0]) {
          this.delChip($span);
        }
        this.disableDelFlag($textField);
      }
    }
  }
  keydownController(e, $textField) {
    // 日本語変換に対応するため、downで処理をする必要がある

    // enter
    if ( e.which == 13 ) {
      this.addChip($textField);
    }
    // space
    if ( e.which == 32 ) {
      this.addChip($textField);
    }
    // comma
    if ( e.which == 44 ) {
      this.addChip($textField);
    }
    // JPN space
    if ( e.which == 12288 ) {
      this.addChip($textField);
    }
    // // countの前にdelete判定をいれて「0で押していたら」という状況を取得できるようにする
    // if(e.which == 8) {
    //   this.keyDelChip($textField);
    // }
  }
  keyupController(e, $textField) {
    if (e.which == 8) {
      this.keyDelChip($textField);
    }
    this.setTextCount($textField);
  }
  textFieldFocus(e, $chipRoot) {
    var $root = this.getRoot($chipRoot);
    var $textField = this.getTextField($root);
    $textField.focus();
  }
}
