export const formsTextCount = {
  addEvent() {
    // keyup
    $('body').on('keyup', '.jsFormTextAreaCount', (e) => {
      this.calcTextCount(e.target);
    });

    $('body').on('keyup', '.jsFormTextFieldCount', (e) => {
      this.calcTextCount(e.target);
    });

    // 初期化
    $('.jsFormTextAreaCount').each((i, input) => {
      this.calcTextCount(input);
    });

    $('.jsFormTextFieldCount').each((i, input) => {
      this.calcTextCount(input);
    });
  },
  calcTextCount(el) {
    const $this = $(el);
    const limit = $this.data('count');
    const more  = $this.data('more');
    const less  = $this.data('less');

    let text = $this.val();
    const $counterRoot = $this.closest('.form-group').find('.form-text');

    const $textFirst = $counterRoot.children('.form-text-counter-text1');
    const $textMid   = $counterRoot.children('.form-text-counter-text2');
    const $textLast  = $counterRoot.children('.form-text-counter-text3');

    // 改行のsizeはrailsが2でjavascriptは1
    text = text.replace(/\r?\n/g, "aa");

    const curCount = text.length;
    const leftCount = limit - curCount;

    $textMid.html(leftCount);
    if (leftCount < 0 ) {
      $counterRoot.addClass('is-alert');
    } else {
      $counterRoot.removeClass('is-alert');
    }

    // 少なすぎ
    if (more) {
      const $textMore  = $counterRoot.children('.form-text-counter-text4');
      if ( curCount !== 0 && curCount <= more ) {
        $textMore.removeClass('hidden');
      } else {
        $textMore.addClass('hidden');
      }
    }
    // 多すぎ
    if (less) {
      const $textMore  = $counterRoot.children('.form-text-counter-text5');
      if ( less <= curCount ) {
        $textMore.removeClass('hidden');
      } else {
        $textMore.addClass('hidden');
      }
    }
  }
};

