// startされ200msecたったら初期化を行う。
// 表示されると同時に1/3まで進める
// 60秒かけて2/3まで進める
// endで、progressBarがあれば3/3まで進める。なければアタッチをキャンセル。

// START
// window.components.progressBar.show()
// END
// window.components.progressBar.hide()
export class ProgressBar {
  constructor() {
    // 全体進捗パーセント
    this.progressValue = 0;
    // アニメーションタイマーID
    this.firstIntervalId = 0;
    this.secondIntervalId = 0;
    this.lastIntervalId = 0;

    this.showIntervalId1 = 0;
    this.showIntervalId2 = 0;

    // 秒数
    this.msecBeforeFirstStart = 200;
    this.msecToFinishFirstInterval = 400;
    this.msecToFinishSecondInterval = 30000; // 60秒
    this.msecToFinishLastInterval = 80;

    this.domProgressBar = null;
  }
  addEvent() {
    // for TEST
    $('body').on('click', '.progressBarStart', () => {
      this.show();
    });

    $('body').on('click', '.progressBarEnd', () => {
      this.hide();
    });
  }
  show(){
    this.clearAllInterval();
    this.progressValue = 0;
    this.initializeProgress();

    // 数msec待機したら、最初は急速に
    this.showIntervalId1 = setTimeout(()=> this.countFirstInterval(), this.msecBeforeFirstStart);
    // その後はゆっくりと2/3まで
    this.showIntervalId2 = setTimeout(()=> this.countSecondInterval(), this.msecBeforeFirstStart + this.msecToFinishFirstInterval);
  }
  hide() {
    this.clearAllInterval();
    // 既存のカウントを止めて、バーを終わらせる
    if (this.progressValue > 0 && this.progressValue < 100 ) {
      this.countLastInterval();
    }
  }
  clearAllInterval() {
    // 実行中のものをキャンセルする
    clearInterval(this.firstIntervalId);
    clearInterval(this.secondIntervalId);
    clearInterval(this.lastIntervalId);

    // 遅延スタートをキャンセルする
    clearInterval(this.showIntervalId1);
    clearInterval(this.showIntervalId2);
  }
  countFirstInterval(){
    // 33%までをmsecToFinishFirstIntervalで終わらせるので、
    // msecToFinishFirstInterval/33の間隔でコールする
    this.firstIntervalId = setInterval(()=> {
      this.progressValue += 1;
      this.drawProgress();
      if (this.progressValue > 33) {
        clearInterval(this.firstIntervalId);
      }
    }, this.msecToFinishFirstInterval/33);
  }
  countSecondInterval(){
    // 残り33%をmsecToFinishSecondIntervalで終わらせるので、
    // msecToFinishSecondInterval/33の間隔でコールする。
    // しかしこれだとカクカクするので、10倍の間隔でコールして、0.1ずつ加えていく
    this.secondIntervalId = setInterval(()=> {
      this.progressValue += 0.1;
      this.drawProgress();
      if (this.progressValue > 100) {
        clearInterval(this.secondIntervalId);
      }
    }, (this.msecToFinishSecondInterval/66)/10);
  }
  countLastInterval() {
    // 残りX%をmsecToFinichLastIntervalで終わらせる
    // Xは100-progressValue
    const progressRemain = 100 - this.progressValue;
    this.lastIntervalId = setInterval(()=> {
      this.progressValue += 1;
      if (this.progressValue >= 100) {
        this.progressValue = 100;
      }
      this.drawProgress();
      if (this.progressValue >= 100) {
        clearInterval(this.lastIntervalId);
        $(this.domProgressBar).remove();
        this.domProgressBar = null;
        this.progressValue = 0;
      }
    }, this.msecToFinishLastInterval/progressRemain);
  }
  initializeProgress() {
    $('.progress-top').remove();
    this.domProgressBar = null;
    $('body').prepend('<div class="progress-top"><div class="progress-top__bar" data-target="component.progressBar" style="width: 0%"></div></div>');
  }
  drawProgress() {
    if (!this.domProgressBar) {
      this.domProgressBar = $('[data-target="component.progressBar"]')[0];
    }
    this.domProgressBar.setAttribute("style", "width: " + this.progressValue + "%;");
  }
}