var ComponentFixedMenu = function() {
  this.$rootTargetCache = null;
  this.$menuTargetCache = null;
  this.$sizeBaseTargetCache = null;
  this.spBreak = 768;
}

ComponentFixedMenu.prototype = {

  $rootTarget: function(force = false) {
    if (force || !this.$rootTargetCache) { this.$rootTargetCache = $('[data-component="fixedMenuRoot"]') 
    }
    return this.$rootTargetCache
  },

  $menuTarget: function(force = false) {
    if (force || !this.$menuTargetCache) { this.$menuTargetCache = $('[data-component="fixedMenuTarget"]') }
    return this.$menuTargetCache
  },

  $sizeBaseTarget: function(force = false) {
    if (force || !this.$sizeBaseTargetCache) { this.$sizeBaseTargetCache = $('[data-component="fixedMenuSizeBase"]') }
    return this.$sizeBaseTargetCache
  },

  setup: function() {
    const $rootTarget = this.$rootTarget(true)
    $rootTarget.addClass('menu-js-menu-fixed')
    const isPresent = $rootTarget.find('[data-component="fixedMenuSizeBase"]')
    if (!isPresent) {
      $rootTarget.prepend('<div class="menu-js-menu-fixed__support" data-component="fixedMenuSizeBase"></div>');
    }
    $('[data-component="fixedMenuTarget"]').addClass('menu-js-menu-fixed__hover')
    this.setMenuWidthAndLeft(true)
    this.setFixedLayout()
  },

  isSp: function() {
    if (window.innerWidth < this.spBreak) {
      return true
    } else {
      return false
    }
  },

  setMenuWidthAndLeft: function(force = false) {
    var $menuTarget = this.$menuTarget(force)
    if (this.isSp()) {
      $menuTarget.css('width', '')
      $menuTarget.css('left', '')
      return
    }
    var $sizeBaseTarget = this.$sizeBaseTarget(force)
    var cssWidth = $sizeBaseTarget.innerWidth() + 'px'
    var cssOffsetLeft = $sizeBaseTarget.offset().left + 'px'
    $menuTarget.css('width', cssWidth)
    $menuTarget.css('left', cssOffsetLeft)
  },

  setFixedLayout: function(){
    var $menuTarget = this.$menuTarget()
    if (this.isSp()) {
      this.setMenuWidthAndLeft()
      $menuTarget.css('margin-top', '')
      return
    }
    var menuTargetHeight = $menuTarget.outerHeight()

    var $rootTarget = this.$rootTarget()
    var rootTargetTop = $rootTarget.offset().top
    var rootTargetHeight = $rootTarget.outerHeight()

    var currentPointY = window.pageYOffset

    var startY = rootTargetTop
    // はみ出さないように100px手前で終わらせる
    var endY = (rootTargetTop + rootTargetHeight) - menuTargetHeight - 100;

    this.setMenuWidthAndLeft()
    // 固定開始ポイント
    if ( startY <= currentPointY && currentPointY < endY ) {
      // ここはfixedでないと、ガタガタする
      // $menuTarget.css('margin-top', currentPointY - rootTargetTop + 'px')
      $rootTarget.addClass('style-fixed');
      $menuTarget.css('margin-top', '0px')
    // 未開始ポイント
    } else if (currentPointY < rootTargetTop) {
      $menuTarget.css('margin-top', '')
      $rootTarget.removeClass('style-fixed');
    // 終了ポイント
    } else { 
      $menuTarget.css('margin-top', endY - rootTargetTop + 'px')
      $rootTarget.removeClass('style-fixed');
    }
  }
}

// windowに格納
if (!window.components) { window.components = {} }
window.components.fixedMenu = new ComponentFixedMenu();

if ($('[data-component="fixedMenuRoot"]')[0]) {
  $(document).ready(function() {
    window.components.fixedMenu.setup();
  });
  $(window).scroll(function(){
    window.components.fixedMenu.setFixedLayout();
  });
   $(window).resize(function() {
    window.components.fixedMenu.setFixedLayout();
  });
};