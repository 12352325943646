var ComponentFormSuggest = function() {
  this.classText      = 'span.suggest-text';
  this.classRoot      = '.form-suggest';
  this.classSuggest   = '.suggest-holder';
  this.classContainer = '.suggest-container';
  this.classList      = '.suggest-list';
  this.inputBefore    = '';
  this.timerId = null;
  this.delayMillSec = 250;
};

ComponentFormSuggest.prototype = {
  suggest: function($input) {
    var value = $input.val();

    // 値がかわっていなければ送信しない
    if (this.inputBefore == value) {
      return false;
    } else {
      this.inputBefore = value;
    }
    if (value) {
      this.deleyPost($input);
    } else {
      this.blur($input);
    }
  },
  deleyPost: function($input) {
    var rootThis = this;
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(function(){
      rootThis.post($input);
    }, this.delayMillSec);
  },
  post: function($input) {
    var $root = this.getRoot($input);
    var url = $root.data('url');
    var keyword = $input.val();
    var rootThis = this;
    $.ajax({
      type: "GET",
      url: url + '?keyword=' + keyword,
    })
    .done(function (response, textStatus, jqXHR) {
      rootThis.show($input, response);
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
    });
  },
  show: function($input, json) {
    if (json.length == 0) { return false; }
    var $root = this.getRoot($input);
    var $suggest = $root.children(this.classSuggest);
    if ($suggest[0]) {
      $suggest.removeClass('hidden');
      var $ul = $suggest.find('ul');
      $ul.remove();
    } else {
      $root.append("<div class='" + this.classSuggest.replace('.','') + "'><div class='" + this.classContainer.replace('.','') +"'><div class='suggest-title'>"+ this.getTitle() + "</div></div></div>");
      $suggest = $root.children(this.classSuggest);
    }
    $suggest.children(this.classContainer).append('<ul></ul>');
    var $ul = $suggest.find('ul');
    json.forEach(function(value, index, array){
      if (value.volume) {
        $ul.append('<li class="suggest-list"><span class="suggest-text">' + value.text + '</span><span class="style-ken">' + value.volume + '</span></li>');
      } else {
        $ul.append('<li class="suggest-list"><span class="suggest-text">' + value.text + '</span></li>');
      }
    });
  },
  click: function($list) {
    var text = $list.children(this.classText).text();
    var $root = this.getRoot($list);
    var $input = $root.find('input[type="text"]');
    $input.val(text);
    $input.blur();
  },
  blur: function($input) {
    var $root = this.getRoot($input);
    var $suggest = this.getSuggest($root);
    $suggest.addClass('hidden');
  },
  select: function($input, direction) {
    var $root = this.getRoot($input);
    var $suggest = this.getSuggest($root);
    if ($suggest.hasClass('hidden')) {
      return false;
    }
    var $lists = this.getLists($suggest);
    var activeIndex = this.getActiveIndex($lists);
    var nextIndex;
    if (direction == 'down') {
      nextIndex = activeIndex + 1;
      if (nextIndex > $lists.length - 1) {
        nextIndex = 0;
      }
    } else {
      nextIndex = activeIndex - 1;
      if (nextIndex < 0) {
        nextIndex = $lists.length - 1;
      }
    }
    var $activeList = $($lists[nextIndex]);
    $lists.removeClass('is-active');
    $activeList.addClass('is-active');
    var text = $activeList.children(this.classText).text();
    this.inputBefore = text;
    $input.val(text);
  },
  selectEnterKey: function($input) {
    // 直下にchipがなければ、選択解除
    var $root = this.getRoot($input);
    var blockingClasses = ['.form-chip'];
    var blocking = false;
    blockingClasses.forEach(function(value, index, array){
      var findObjects = $root.find(value);
      if (findObjects[0]) {
        blocking = true;
      }
    });
    if (blocking) {
      return false;
    }
    this.blur($input);
  },
  getRoot: function($object) {
    return $object.closest(this.classRoot);
  },
  getSuggest: function($root) {
    return $root.children(this.classSuggest);
  },
  getLists: function($suggest) {
    return $suggest.find('li');
  },
  getActiveIndex: function($lists) {
    var activeIndex = -1;
    $lists.each(function(index, element){
      var $list = $(element);
      if ($list.hasClass('is-active')) {
        activeIndex = index;
      }
    });
    return activeIndex;
  },
  getTitle: function($input) {
    return '検索候補';
  }
};

// windowに格納
if (!window.components) { window.components = {};}
window.components.formSuggest = new ComponentFormSuggest();

$(document).on('keyup', ".form-suggest input[type='text']", function(e){
  window.components.formSuggest.suggest($(this), e);
});

$(document).on('mousedown', ".form-suggest .suggest-list", function(e){
  window.components.formSuggest.click($(this));
});

$(document).on('blur', ".form-suggest input[type='text']", function(e){
  window.components.formSuggest.blur($(this));
});

// キー操作
$(document).on('keydown', ".form-suggest input[type='text']", function(e){
  // エンターのフォーム送信を無効にする
  if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
    window.components.formSuggest.selectEnterKey($(this));
    return false;
  }
  if (e.which == 40) {
    window.components.formSuggest.select($(this), 'down');
  }
  if (e.which == 38) {
    window.components.formSuggest.select($(this), 'up');
  }
  // esc
  if (e.which == 27) {
    window.components.formSuggest.blur($(this));
  }
});

