export const switcher = {
  // data
  data($this) {
    return  {
      'element': $this,
      'resetFormsIds': $this.data('reset-form-ids')
    };
  },
  addEvent() {
    $(document).on('click.switcher', '[data-toggle="switcher"]', (e) => {
      this.toggle(e.target);
    });
  },
  // target
  getParent($this) {
    return $this.closest('.switcher-block');
  },
  // methods
  toggle: function (el) {
    const $this   = $(el);
    const $parent = this.getParent($this);
    const $activeList = $parent.children('.switcher-list.active');
    const $inActiveList = $parent.children('.switcher-list:not(.active)');
    $activeList.removeClass('active');
    $inActiveList.addClass('active');
    if (this.data($this).resetFormsIds) {
      var self = this;
      this.data($this).resetFormsIds.forEach((id, index, array) => {
        self.resetForm(id);
      });
    }
  },
  resetForm: function(id) {
    var $form = $('#'+id);
    $form.val('');
  }
};


