export const formsTextAreaExample = {
  addEvent() {
    $('body').on('click', ".form-text-area-example-action-close", (e) => {
      this.close($(e.target));
    });
    $('body').on('click', ".form-text-area-example-action-open", (e) => {
      this.open($(e.target));
    });
  },
  open($btn) {
    $btn.closest('.form-text-area-example').addClass('style-open');
  },
  close($btn) {
    $btn.closest('.form-text-area-example').removeClass('style-open');
  }
};

